import React from 'react';
import ModelCanvas from '../components/ModelCanvas';
import Layout from '../components/Layout';
import Button from '../components/Button';

export default function indexPage() {
  const isBrowser = typeof window !== 'undefined';

  return (
    <Layout fullMenu>
      <article id="main">
        <header>
          <h2>Tetralogy of Fallot</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner model">
            <section>
              <div className="row">
                <div className="col-12 col-12-medium">
                  <h2>Tetralogy of Fallot</h2>
                  <p>
                    classically combines these four defects: <br />
                    1. pulmonary stenosis, <br />
                    2. ventricular septal defect, <br />
                    3. ventricular hypertrophy, <br />
                    4. an overriding aorta. <br />
                    <br />
                    <b>Tetralogy of Fallot</b> is typically treated by open
                    heart surgery in the first year of life. Untreated, rapidly
                    results in progressive right ventricular hypertrophy due to
                    the increased resistance caused by narrowing of the
                    pulmonary trunk. This progresses to heart failure which
                    begins in the right ventricle and often leads to left heart
                    failure and dilated cardiomyopathy. Depending on the
                    patient's condition, <b>palliative</b> or <b>complete</b>{' '}
                    surgical intervention can be chosen.
                  </p>
                  <p>
                    About the 1st model: This 3D model is based on CT
                    angiography of a patient after a <b>palliative</b> surgical
                    correction. You can recognize multiple aortopulmonary
                    collaterals and retroesophageal right subclavian artery
                    after previous surgical central shunt (Hillel Laks)
                    construction.
                  </p>
                </div>
              </div>
              <div className="row">
                {isBrowser && (
                  <div className="col-12 col-12-medium">
                    <ModelCanvas
                      model_path={'/tetralogy-of-fallot-1st.glb'}
                      camera_position={[3, 3, 3]}
                      light_intensity={0.4}
                      group_rotation={[0, 0, 0]}
                      group_position={[0.3, -0.3, 0.1]}
                      group_scale={[20, 20, 20]}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-12 col-12-medium">
                  <p>
                    <br />
                    <br />
                    About the 2nd model: This 3D model is based on CT
                    angiography of a patient after a <b>complete</b> surgical
                    correction. You can recognize dilated right outflow tract of
                    the right ventricle and a residual left pulmonary artery
                    (LPA) stenosis.
                  </p>
                </div>
              </div>
              <div className="row">
                {isBrowser && (
                  <div className="col-12 col-12-medium">
                    <ModelCanvas
                      model_path={'/tetralogy-of-fallot-2nd.glb'}
                      camera_position={[3, 3, 3]}
                      light_intensity={0.2}
                      group_rotation={[0, 0, -0.1]}
                      group_position={[-0.3, -0.3, 3.5]}
                      group_scale={[20, 20, 20]}
                    />
                  </div>
                )}
                <p>
                  <br />
                  <br />
                  The majority of the patients seemed to live normal lives 20–37
                  years after the surgery.
                </p>
              </div>
            </section>
            <div>
              <br />
              <ul className="actions stacked">
                <li>
                  <br />
                  <Button
                    buttonClass="button small primary"
                    url="https://biovoxel.tech/en/services/organ-models/"
                    text="Print 3D Model"
                    action="print"
                  />{' '}
                  <Button
                    buttonClass="button icon solid fa-coffee"
                    url="https://buymeacoff.ee/3DHearter"
                    text="^^"
                    action="donate"
                  />
                </li>
              </ul>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
}
